import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from '../axiosBaseQuery';
import { ScheduleRequest, ScheduleResponse } from './interface';
import { endOfMonth, formatISO, startOfMonth } from 'date-fns';

const URL = '/api/Schedule';

export const scheduleApi = createApi({
  reducerPath: 'scheduleApi',
  baseQuery: axiosBaseQuery(),
  tagTypes: ['ScheduleTask'],
  refetchOnMountOrArgChange: true,
  endpoints: (builder) => ({
    querySchedule: builder.query<ScheduleResponse, ScheduleRequest>({
      query: (data) => ({
        url: `${URL}/QuerySchedule`,
        method: 'POST',
        data: data,
      }),
      providesTags: [{ type: 'ScheduleTask' }],
      transformResponse: (response) => response.data.data,
      serializeQueryArgs: ({ endpointName }) => endpointName,
      forceRefetch: ({ currentArg, previousArg }) => {
        return currentArg?.StartDate !== previousArg?.StartDate;
      },
    }),
    queryMonthlySchedule: builder.query<
      ScheduleResponse,
      { GroupId: number; date: string }
    >({
      query: ({ GroupId, date }) => {
        const parsedDate = new Date(date);
        const startDate = startOfMonth(parsedDate);
        const endDate = endOfMonth(parsedDate);
        return {
          url: `${URL}/QuerySchedule`,
          method: 'POST',
          data: {
            GroupId: GroupId,
            StartDate: formatISO(startDate),
            EndDate: formatISO(endDate),
          },
        };
      },
      transformResponse: (response) => response.data.data,
    }),
  }),
});

export const { useQueryScheduleQuery, useQueryMonthlyScheduleQuery } =
  scheduleApi;
